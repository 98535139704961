import React from 'react';
import { Link } from 'react-router-dom';
import Connect from '../../../components/ConnectWithLifeCycle';
import { authSignout } from '../../../actions/auth';

const bodyEl = document.querySelector('body');
const showHideNavBar = () => {
  const isOpen = bodyEl.classList.contains('navopen');
  if (isOpen) {
    bodyEl.classList.remove('navopen');
    bodyEl.classList.add('navbigopen');
  } else {
    bodyEl.classList.add('navopen');
    bodyEl.classList.remove('navbigopen');
  }
};

const Header = props => (
  <Connect
    componentDidMount={() => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
        .test(navigator.userAgent)) {
        bodyEl.classList.add('navopen');
      }
    }}
  >
    {({
      dispatch,
    }) => (
        <div className="header">
          <nav className="navbar navbar-expand-md bg-light navbar-light justify-content-between fixed-top">
            <div>
              <div className="navbar-header">
                <a className="navbar-brand" href="/profile">
                  <b className="minlogo"><img src={`${process.env.REACT_APP_CDN_URL}img/min-logo.png`} alt="Logo" className="img-fluid" /></b>
                  <span className="logo-left"><img src={`${process.env.REACT_APP_CDN_URL}img/revv-logo-dark.png`} alt="Logo" className="img-fluid" /></span>
                </a>
              </div>
            </div>
            <div className="navbar-collapse" id="collapsibleNavbar">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <span className="navbar-toggler-icon" />
              </button>
              <ul className="navbar-nav accordion">
                <li className="nav-item" id="navItem"><Link to="#" onClick={() => showHideNavBar()} className="nav-link sidebartoggler hidden-sm-down text-muted"><img src={`${process.env.REACT_APP_CDN_URL}img/hamburger.svg`} alt="revrallio" className="img-fluid iconwrap" /></Link></li>
              </ul>
            </div>
            <div className="nav-item dropdown revvprofwrap">
              <div className="nav-link dropdown-toggle" id="navbardrop" data-toggle="dropdown">
                <i className="fa fa-user-circle navbar-usericon" />
                <span className="navbar-usertext">{localStorage.getItem('first_name')}</span>
              </div>
              <div className="dropdown-menu dropdown-menu-right">
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={() => {
                    dispatch(authSignout(props));
                  }}
                >
                  Logout
              </Link>
              </div>
            </div>
          </nav>
        </div>
      )}
  </Connect>
);

export default Header;
