
import React from 'react';

const currYear = new Date().getFullYear();

const Footer = () => (
  <div className="footer axim-version">
    <div className="pull-left">Copyright © {currYear} All Rights Reserved.</div>
    <div className="pull-right"><a href="">Privacy policy </a> | <a href="">Terms of use</a></div>
  </div>
);

export default Footer;
