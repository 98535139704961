import { takeEvery, put } from 'redux-saga/effects';
import { apiCall } from '../../util';
import * as userActionTypes from '../../actions/actionTypes/signature';
import { signatureResponse } from '../../actions/signature/index';

function* sendSignatureRequest(userDetails) {
  const response = yield apiCall('getsignature', 'POST', userDetails);
  yield put(signatureResponse(response.data));
  window.location.href = '/payment';
}

export function* takeSignatureRequest(): Generator<*, *, *> {
  yield takeEvery(
    userActionTypes.SIGNATURE_REQUEST,
    sendSignatureRequest,
  );
}
