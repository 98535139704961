import * as actionTypes from '../actionTypes/stripe';

export function stripeRequest(payload, props) {
  return { type: actionTypes.STRIPE_REQUEST, payload, props };
}

export function stripeRefundRequest(payload, props) {
  return { type: actionTypes.STRIPE_REFUND_REQUEST, payload, props };
}

export function stripeRequestFail(error) {
  return { type: actionTypes.STRIPE_REQUEST_FAIL, error };
}

export function stripeResponse(data) {
  return { type: actionTypes.STRIPE_RESPONSE, data };
}

export function stripeTransactionResponse(data) {
  return { type: actionTypes.STRIPE_TRANSACTION_RESPONSE, data };
}

export function getTransactionListRequest(payload, props) {
  return { type: actionTypes.STRIPE_TRANSACTION_REQUEST, payload, props };
}

export function onHideModal(props) {
  return { type: actionTypes.HIDE_MODAL, props };
}
