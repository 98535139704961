import { takeEvery, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { apiCall } from '../../util';
import * as userActionTypes from '../../actions/actionTypes/signup';
import { userProfileRequestFail, userProfileResponse, onHideModal } from '../../actions/users/signup';
import { authRequestFail, authResponse } from '../../actions/auth';

function* sendUserProfileRequest(payload) {
	
  const userDetails = { ...payload, subscription: null };
  const response = yield apiCall('signup', 'POST', userDetails);
  if (response.status !== 200) {
    yield put(userProfileResponse(''));
    yield put(userProfileRequestFail(response.data.error));
    yield (delay(3000));
    yield put(onHideModal());
  } else {
    yield put(userProfileRequestFail(''));
    yield put(userProfileResponse(response.data.message));
    yield (delay(3000));
    yield put(onHideModal());

    const signinresponse = yield apiCall('auth', 'POST', userDetails);
    if (signinresponse.status !== 200) {
      yield put(authResponse(''));
      yield put(authRequestFail(signinresponse.data.message));
      yield (delay(3000));
      yield put(onHideModal());
    } else {
      const json = yield signinresponse.data;
      window.localStorage.setItem('JWT', json.data.jwt);
      window.localStorage.setItem('userid', json.data.user_id);
      window.localStorage.setItem('username', json.data.username);
      window.localStorage.setItem('first_name', json.data.first_name);
      window.localStorage.setItem('last_name', json.data.last_name);
      window.localStorage.setItem('useremail', json.data.email);
      window.localStorage.setItem('companyid', json.data.companyid);
      window.localStorage.setItem('roleid', json.data.role_id);
      window.localStorage.setItem('stripecustomerid', json.data.stripe_customerid);
      window.localStorage.setItem('numoflocations', json.data.numoflocations);
      window.localStorage.setItem('userdoc_url', json.data.userdoc_url);
      global.jwt = json.data.jwt;
      yield put(authResponse({ ...json }));
      yield put(authRequestFail(''));
      if (json.data.role_id !== 1) {
        if (json.data.userdoc_url !== null && json.data.userdoc_url !== undefined && json.data.userdoc_url !== '') {
          userDetails.props.history.push('/payment');
        } else {
          userDetails.props.history.push('/terms');
        }
      } else {
        userDetails.props.history.push('/userlist');
      }
    }
    //userDetails.props.history.push('/');
  }
}

export function* takeSignupRequest(): Generator<*, *, *> {
  yield takeEvery(
    userActionTypes.USER_PROFILE_REQUEST,
    sendUserProfileRequest,
  );
}
