import * as actionTypes from '../../actions/actionTypes/updatelocation';

const initialState = {
  isFetching: false,
  error: '',
  isModal: false,
  message: '',
  values: null,
  modalShow: false,
};

export default (prevState = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATELOCATION_REQUEST:
      return Object.assign({}, prevState, { isFetching: true, isModal: false });
    case actionTypes.UPDATELOCATION_RESPONSE:
      return Object.assign({}, prevState, { isFetching: false, message: action.data.message, error: action.data.error !== undefined ? action.data.error : '', isModal: true });
    case actionTypes.HIDE_MODAL:
      return Object.assign({}, prevState, { isModal: false });
    default:
      return prevState;
  }
};
