import * as actionTypes from '../actionTypes/forgotPassword';

export function userForgotPasswordRequest(payload, props) {
  return { type: actionTypes.USER_FORGOT_PASSWORD_REQUEST, payload, props };
}

export function userForgotPasswordRequestFail(error) {
  return { type: actionTypes.USER_FORGOT_PASSWORD_REQUEST_FAIL, error };
}

export function userForgotPasswordResponse(data) {
  return { type: actionTypes.USER_FORGOT_PASSWORD_RESPONSE, data };
}

export function onHideModal(props) {
  return { type: actionTypes.HIDE_MODAL, props };
}
