import * as actionTypes from '../actionTypes/signature';

export function signatureRequest(payload, props) {
  return { type: actionTypes.SIGNATURE_REQUEST, payload, props };
}

export function signatureRequestFail(error) {
  return { type: actionTypes.SIGNATURE_REQUEST_FAIL, error };
}

export function signatureResponse(data) {
  return { type: actionTypes.SIGNATURE_RESPONSE, data };
}

export function onHideModal(props) {
  return { type: actionTypes.HIDE_MODAL, props };
}
