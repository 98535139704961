import React from 'react';
import Connect from '../../../components/ConnectWithLifeCycle';

const bodyEl = document.querySelector('body');

const LoginHeader = props => (
  <Connect
    componentDidMount={() => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
        .test(navigator.userAgent)) {
        bodyEl.classList.add('navopen');
      }
    }}
  >
    {({
      dispatch,
    }) => (
        <div className="login-header header">
          {/* Desktop header section */}
          <nav className="navbar navbar-expand-md bg-light navbar-light justify-content-between fixed-top desktop">

            <div className="navbar-header">
              <a className="navbar-brand" href="/profile">
                <span className="logo-left"><img src={`${process.env.REACT_APP_CDN_URL}img/revv-logo-dark.png`} alt="Logo" className="img-fluid" /></span>
              </a>
            </div>


            <div className="header-desktop">
              <ul className="nav navbar-nav">


                <li className="nav-item">
                  <a className="nav-link" href="/sign-in">Sign In</a>
                </li>
                <li className="nav-item">
                  <a type="submit" href="/sign-up"><span className="login-sign-btn-header">Create an account</span></a>
                </li>
              </ul>
            </div>
          </nav>

          {/* Mobile section */}

          <nav className="navbar navbar-expand-md bg-light navbar-light justify-content-between fixed-top mobile">
            <div className="navbar-header">
              <a className="navbar-brand" href="/profile">
                <span className="logo-left"><img src={`${process.env.REACT_APP_CDN_URL}img/revv-logo-dark.png`} alt="Logo" className="img-fluid" /></span>
              </a>
            </div>

            <ul className="top-section-right-btn">
              <li className="h-c-header__cta-li h-c-header__cta-li--primary">
                <a type="submit" href="/sign-up"><span className="login-sign-btn-header">Create an account</span></a>
              </li>
            </ul>
            <hr className="mobile-header-hr"></hr>
            <div className="second-section-top">
              <ul className="h-c-header__cta-list h-c-header__cta-list--tier-two">
                {/* <li className="h-c-header__cta-li g-mail-nav-links--mobile ">
                  <a className="nav-link" href="/forgot-password">Forgot Password</a>
                </li> */}
                <li className="h-c-header__cta-li g-mail-nav-links--mobile  link--last">
                  <a className="nav-link" href="/sign-in">Sign In</a>
                </li>
              </ul>
            </div>
          </nav>



        </div>
      )}
  </Connect>
);

export default LoginHeader;
