import { takeEvery, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { apiCall } from '../../util';
import * as userActionTypes from '../../actions/actionTypes/updatelocation';
import { onHideModal, updateLocationResponse } from '../../actions/updatelocation/index';

function* updateLocationsRequest(userDetails) {
  const response = yield apiCall('updateLocationAndSubscription', 'POST', userDetails);
  if (response.status !== 200) {
    yield put(updateLocationResponse(''));
    yield (delay(3000));
  } else {
    yield put(updateLocationResponse(response.data));
    yield (delay(3000));
    yield put(onHideModal());
    if (response.data.error !== undefined && response.data.error === '') {
      window.location.href = '/profile';
    }
  }
}

export function* takeUpdateLocationRequest(): Generator<*, *, *> {
  yield takeEvery(
    userActionTypes.UPDATELOCATION_REQUEST,
    updateLocationsRequest,
  );
}
