import * as actionTypes from '../actionTypes/updatelocation';

export function updateLocationRequest(payload, props) {
  return { type: actionTypes.UPDATELOCATION_REQUEST, payload, props };
}

export function updateLocationResponse(data) {
  return { type: actionTypes.UPDATELOCATION_RESPONSE, data };
}

export function onHideModal(props) {
  return { type: actionTypes.HIDE_MODAL, props };
}
