import * as actionTypes from '../actionTypes/signup';

export function userProfileRequest(payload, props) {
  return { type: actionTypes.USER_PROFILE_REQUEST, payload, props };
}

export function userProfileRequestFail(error) {
  return { type: actionTypes.USER_PROFILE_REQUEST_FAIL, error };
}

export function userProfileResponse(message) {
  return { type: actionTypes.USER_PROFILE_RESPONSE, message };
}

export function onHideModal(props) {
  return { type: actionTypes.HIDE_MODAL, props };
}
