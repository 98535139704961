import { takeEvery, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { apiCall } from '../../util';
import * as userActionTypes from '../../actions/actionTypes/resetPassword';
import { userResetPasswordRequestFail, onHideModal, userResetPasswordResponse } from '../../actions/users/resetPassword';

function* sendResetPasswordRequest(userDetails) {
  const response = yield apiCall('resetpassword', 'POST', userDetails);
  if (response.status !== 200) {
    yield put(userResetPasswordResponse(''));
    yield put(userResetPasswordRequestFail(response.data.error));
    yield (delay(3000));
    yield put(onHideModal());
  } else {
    yield put(userResetPasswordRequestFail(''));
    yield put(userResetPasswordResponse(response.data.message));
    yield (delay(3000));
    yield put(onHideModal());
    userDetails.props.history.push('/');
  }
}

export function* takeResetPasswordRequest(): Generator<*, *, *> {
  yield takeEvery(
    userActionTypes.USER_RESET_PASSWORD_REQUEST,
    sendResetPasswordRequest,
  );
}
