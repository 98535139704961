import { takeEvery, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { apiCall } from '../../util';
import * as userActionTypes from '../../actions/actionTypes/stripe';
import { onHideModal, stripeResponse, stripeTransactionResponse, stripeRequestFail } from '../../actions/stripe/index';

function* sendStripeRequest(userDetails) {
  const check = yield apiCall('getSubscribed', 'POST', userDetails);
  if (check.status === 200) {
    if (check.data === 'success') {
      yield (delay(3000));
      yield put(onHideModal());
    }
  }
  if (check.data === 0) {
    const response = yield apiCall('payCharge', 'POST', userDetails);
    if (response.status !== 200) {
      yield put(stripeResponse(response.data));
      yield (delay(3000));
      yield put(onHideModal());
    } else {
      yield put(stripeResponse(response.data));
      yield (delay(3000));
      //yield put(onHideModal());
      localStorage.setItem("ispaymentdone", "yes");
      yield apiCall('updateSubscription', 'POST', userDetails);
      //window.location.href = '/profile';
    }
  } else {
    if (check.data.error !== undefined && check.data.error.message !== null) {
      yield put(stripeResponse({ message: '', error: check.data.error.message }));
    } else {
      yield put(stripeResponse({ message: '', error: 'You have already been subscribed to recurring payment!' }));
    }
    yield (delay(3000));
    yield put(onHideModal());
  }
}

function* getTransactionListRequest(userDetails) {  
  try {
    const response = yield apiCall('getTransactions', 'POST', userDetails);
    if (response.status === 200) {
	 if (response.data.data.length !== 0) {
		document.querySelector('#divrefund').style.display = 'block';
	}
      yield put(stripeTransactionResponse(response.data.data));
      if(response.data.data.length > 0){
        localStorage.setItem("ispaymentdone", "yes");
      } else {
        localStorage.setItem("ispaymentdone", "no");
      }
      yield (delay(1000));
    } else {
      yield put(stripeRequestFail(response.data.error.message));
      yield (delay(3000));
      yield put(onHideModal());
    }
  } catch (exception) {
    console.log('Excpetion occurred on..getTransactionListRequest');
  }
}

function* sendRefundRequest(refundDetails) {
  const response = yield apiCall('sendrefundemail', 'POST', refundDetails);
  if (response.status !== 200) {
    yield put(stripeRequestFail(response.data.error.message));
    yield (delay(3000));
    yield put(onHideModal());
  }
}

export function* takeStripeRequest(): Generator<*, *, *> {
  yield takeEvery(
    userActionTypes.STRIPE_REQUEST,
    sendStripeRequest,
  );
}

export function* getTransactionListResponse(): Generator<*, *, *> {
  yield takeEvery(
    userActionTypes.STRIPE_TRANSACTION_REQUEST,
    getTransactionListRequest,
  );
}

export function* getRefundRequest(): Generator<*, *, *> {
  yield takeEvery(
    userActionTypes.STRIPE_REFUND_REQUEST,
    sendRefundRequest,
  );
}