import * as actionTypes from '../../actions/actionTypes/forgotPassword';

const initialState = {
  email: '',
  isFetching: false,
  error: '',
  isModal: false,
  message: '',
};

export default (prevState = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_FORGOT_PASSWORD_REQUEST:
      return Object.assign({}, prevState, { isFetching: true, isModal: false });
    case actionTypes.USER_FORGOT_PASSWORD_REQUEST_FAIL:
      return Object.assign({}, prevState, { isFetching: false, error: action.error, isModal: true });
    case actionTypes.USER_FORGOT_PASSWORD_RESPONSE:
      return Object.assign({}, prevState, { isFetching: false, message: action.data, isModal: true });
    case actionTypes.HIDE_MODAL:
      return Object.assign({}, prevState, { isModal: false });
    default:
      return prevState;
  }
};
