import React from 'react';
import { connect } from 'react-redux';

class ConnectWithLifeCycle extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.initialState || {};
    this.handlers = Object.keys(this.props.handlers || {})
      .reduce(
        (
          handlers,
          handlerName,
        ) => {
          handlers[handlerName] = args => this.props.handlers[handlerName](this.props)(args);
          return handlers;
        },
        {},
      );
  }

  componentWillMount() {
    if (this.props.componentWillMount) {
      this.props.componentWillMount(this.props);
    }
  }

  componentDidMount() {
    if (this.props.componentDidMount) {
      this.props.componentDidMount(this.props);
    }
  }

  componentWillReceiveProps() {
    if (this.props.componentWillReceiveProps) {
      this.props.componentWillReceiveProps(this.props);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.componentWillUpdate) {
      this.props.componentWillUpdate(nextProps, nextState);
    }
  }

  componentWillUnmount() {
    if (this.props.componentWillUnmount) {
      this.props.componentWillUnmount(this.props);
    }
  }

  getRenderedComponent() {
    return this.component;
  }

  render() {
    console.log(this.handlers);
    return this.props.children(
      this.props,
      {
        handlers: this.handlers,
        setState: (updater, callback) => this.setState(updater, callback),
        state: this.state,
        ref: el => this.component = el, // eslint-disable-line
      },
      this
    );
  }
}

export default connect(
  (state, { mapStateToProps, ...props }) => (mapStateToProps ? mapStateToProps(state, props) : {}),
  dispatch => ({
    dispatch,
  }),
)(ConnectWithLifeCycle);
