import React from 'react';

const PageNotFound = location => (
  <div>
    <h3>
      {`Sorry, The ${location.pathname}  page not found this application`}
    </h3>
  </div>);

export default PageNotFound;
