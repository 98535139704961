import * as actionTypes from '../actionTypes/userlist';

export function getUserDetailsRequest(payload, props) {
  return { type: actionTypes.GET_USERDETAILS_REQUEST, payload, props };
}

export function getUserPaginateDetailsRequest(payload, props) {
  return { type: actionTypes.GET_USER_PAGINATE_DETAILS_REQUEST, payload, props };
}

export function getUserDetailsRequestFail(error) {
  return { type: actionTypes.GET_USERDETAILS_FAIL, error };
}

export function getUserDetailsResponse(data) {
  return { type: actionTypes.GET_USERDETAILS_RESPONSE, data };
}

export function onHideModal(props) {
  return { type: actionTypes.HIDE_MODAL, props };
}

export function userCardFlip(userID) {
  return { type: actionTypes.FLIP_USER_CARD, userID };
}
