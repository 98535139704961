import { takeEvery, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { apiCall } from '../../util';
import * as actionTypes from '../../actions/actionTypes/auth';
import { authRequestFail, authResponse, onHideModal } from '../../actions/auth';

function* sendAuthRequest(authDetails) {
  const response = yield apiCall('auth', 'POST', authDetails);
  if (response.status !== 200) {
    yield put(authResponse(''));
    yield put(authRequestFail(response.data.message));
    yield (delay(3000));
    yield put(onHideModal());
  } else {
    const json = yield response.data;

    const payload = { "payload": {stripecustomerid: `${json.data.stripe_customerid}` }};
    const dataresponse =  yield apiCall('getTransactions', 'POST', payload);

    if(dataresponse.data.data.length > 0){
      localStorage.setItem("ispaymentdone", "yes");
    } else {
      localStorage.setItem("ispaymentdone", "no");
    }
    
    window.localStorage.setItem('JWT', json.data.jwt);
    window.localStorage.setItem('userid', json.data.user_id);
    window.localStorage.setItem('username', json.data.username);
    window.localStorage.setItem('first_name', json.data.first_name);
    window.localStorage.setItem('last_name', json.data.last_name);
    window.localStorage.setItem('useremail', json.data.email);
    window.localStorage.setItem('companyid', json.data.companyid);
    window.localStorage.setItem('roleid', json.data.role_id);
    window.localStorage.setItem('stripecustomerid', json.data.stripe_customerid);
    window.localStorage.setItem('numoflocations', json.data.numoflocations);
    window.localStorage.setItem('userdoc_url', json.data.userdoc_url);
    global.jwt = json.data.jwt;
    yield put(authResponse({ ...json }));
    yield put(authRequestFail(''));
    if (json.data.role_id !== 1) {
      if (json.data.userdoc_url !== null && json.data.userdoc_url !== undefined && json.data.userdoc_url !== '') {
        authDetails.props.history.push('/payment');
      } else {
        authDetails.props.history.push('/terms');
      }
    } else {
      authDetails.props.history.push('/userlist');
    }
  }
}

function* sendAuthSignOut(authDetails) {
  yield put(authResponse(authDetails));
  global.jwt = '';
  localStorage.clear();
  authDetails.props.history.push('/');
}

export function* takeLoginRequest(): Generator<*, *, *> {
  yield takeEvery(
    actionTypes.AUTH_REQUEST,
    sendAuthRequest,
  );
}

export function* takeLogoutRequest(): Generator<*, *, *> {
  yield takeEvery(
    actionTypes.AUTH_SIGNOUT,
    sendAuthSignOut,
  );
}