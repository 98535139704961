import { all, fork } from 'redux-saga/effects';
import { takeProfileRequest, updProfileRequest, delProfileRequest } from './profile';
import { takeUpdateLocationRequest } from './updatelocation';
import { takeLoginRequest, takeLogoutRequest } from './auth';
import { takeSignupRequest } from './users/signup';
import { takeForgotPasswordRequest } from './users/forgotPassword';
import { takeResetPasswordRequest } from './users/resetPassword';
import { takeUserListRequest, takePaginateUserListRequest } from './admin/userlist';
import { takeStripeRequest, getTransactionListResponse, getRefundRequest } from './stripe/index';
import { takeSignatureRequest } from './signature/index';

function* root() {
  yield all([
    fork(takeLoginRequest),
    fork(takeLogoutRequest),
    fork(takeSignupRequest),
    fork(takeForgotPasswordRequest),
    fork(takeResetPasswordRequest),
    fork(takeProfileRequest),
    fork(updProfileRequest),
    fork(delProfileRequest),
    fork(takeUpdateLocationRequest),
    fork(takeUserListRequest),
    fork(takePaginateUserListRequest),
    fork(takeStripeRequest),
    fork(getTransactionListResponse),
    fork(getRefundRequest),
    fork(takeSignatureRequest),
  ]);
}

export default root;
