import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Header from '../containers/router/header';
import SideMenu from './menu';
import Footer from '../containers/router/footer';

const PrivateRoute = ({ component: Component, header, footer, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      localStorage.getItem('JWT')
        ? (
          <div>
            {header ? <Header {...props} /> : ''}
            <SideMenu {...props} />
            <Component {...props} />
            {footer ? <Footer /> : ''}
          </div>
        )
        : <Redirect to="/" />
    )}
  />
);

export default PrivateRoute;
