import React from 'react';
import { Route } from 'react-router-dom';
import Header from '../containers/router/header';
import Footer from '../containers/router/footer';
import LoginHeader from '../containers/router/header/loginHeader';
import LoginFooter from '../containers/router/footer/loginFooter';

const PublicRoute = ({ component: Component, header, footer, loginHeader, loginFooter, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <div>
        {header ? <Header /> : loginHeader ? <LoginHeader /> : ''}
        <Component {...props} />
        {footer ? <Footer /> : loginFooter ? <LoginFooter /> : ''}
      </div>
    )}
  />
);

export default PublicRoute;
