import * as actionTypes from '../../actions/actionTypes/profile';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  isFetching: false,
  error: '',
  isModal: false,
  captcha: '',
  message: '',
  usersdata: null,
  modalShow: false,
};

export default (prevState = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_GETPROFILE_REQUEST:
    case actionTypes.USER_UPDATEPROFILE_REQUEST:
    case actionTypes.USER_DELETEPROFILE_REQUEST:
      return Object.assign({}, prevState, { isFetching: true, isModal: false });
    case actionTypes.USER_GETPROFILE_REQUEST_FAIL:
      return Object.assign({}, prevState, { isFetching: false, message: action.data !== undefined ? action.data.data.message : null, error: action.error, isModal: true });
    case actionTypes.USER_GETPROFILE_RESPONSE:
      return Object.assign({}, prevState, { isFetching: false, usersdata: action.data, isModal: false });
    case actionTypes.USER_UPDATEPROFILE_RESPONSE:
    case actionTypes.USER_DELETEPROFILE_RESPONSE:
      return Object.assign({}, prevState, { isFetching: false, message: action.data, error: action.error !== undefined ? action.error : '', isModal: true });
    case actionTypes.HIDE_MODAL:
      return Object.assign({}, prevState, { isModal: false });
    default:
      return prevState;
  }
};
