import * as actionTypes from '../../actions/actionTypes/userlist';

const initialState = {
  isFetching: false,
  error: '',
  isModal: false,
  message: '',
  usersdata: [],
  activeUserID: null,
};

export default (prevState = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERDETAILS_REQUEST:
    case actionTypes.GET_USER_PAGINATE_DETAILS_REQUEST:
      return Object.assign({}, prevState, { isFetching: true, isModal: false });
    case actionTypes.GET_USERDETAILS_FAIL:
      return Object.assign({}, prevState, { isFetching: false, error: action.error, isModal: true });
    case actionTypes.GET_USERDETAILS_RESPONSE:
      return Object.assign({}, prevState, { isFetching: false, usersdata: action.data !== undefined ? action.data : [], isModal: false });
    case actionTypes.HIDE_MODAL:
      return Object.assign({}, prevState, { isModal: false });
    case actionTypes.FLIP_USER_CARD:
      return Object.assign({}, prevState, { activeUserID: action.userID });
    default:
      return prevState;
  }
};
