import { takeEvery, put } from 'redux-saga/effects';
import { apiCall } from '../../util';
import * as userActionTypes from '../../actions/actionTypes/userlist';
import { getUserDetailsRequest, getUserPaginateDetailsRequest, getUserDetailsResponse } from '../../actions/admin/userlist';

function* sendGetProfileRequest(userDetails) {

  const fulluserlistresponse = yield apiCall('getfulluserlist', 'POST', userDetails);
  if (fulluserlistresponse.status !== 200) {
    yield put(getUserDetailsRequest(''));
  } else {
    localStorage.setItem('fulluserlist', Object.keys(fulluserlistresponse.data.data).length);
  }

  const response = yield apiCall('getalluserlist', 'POST', userDetails);
  if (response.status !== 200) {
    yield put(getUserDetailsRequest(''));
  } else {
    yield put(getUserDetailsResponse(response.data));
  }
}

function* sendGetUserPaginateRequest(userDetails) {
  const response = yield apiCall('getpaginateuserlist', 'POST', userDetails);
  if (response.status !== 200) {
    yield put(getUserPaginateDetailsRequest(''));
  } else {
    yield put(getUserDetailsResponse(response.data));
  }
}

export function* takeUserListRequest(): Generator<*, *, *> {
  yield takeEvery(
    userActionTypes.GET_USERDETAILS_REQUEST,
    sendGetProfileRequest,
  );
}

export function* takePaginateUserListRequest(): Generator<*, *, *> {
  yield takeEvery(
    userActionTypes.GET_USER_PAGINATE_DETAILS_REQUEST,
    sendGetUserPaginateRequest,
  );
}
