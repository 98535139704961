import * as actionTypes from '../actionTypes/auth';

export function authRequest(payload, props) {
  return { type: actionTypes.AUTH_REQUEST, payload, props };
}

export function authRequestFail(error) {
  return { type: actionTypes.AUTH_REQUEST_FAIL, error };
}

export function authResponse(data) {
  return { type: actionTypes.AUTH_RESPONSE, data };
}

export function authSignout(props) {
  return { type: actionTypes.AUTH_SIGNOUT, props };
}

export function onHideModal(props) {
  return { type: actionTypes.HIDE_MODAL, props };
}
