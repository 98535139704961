import * as actionTypes from '../actionTypes/resetPassword';

export function userResetPasswordRequest(payload, props) {
  return { type: actionTypes.USER_RESET_PASSWORD_REQUEST, payload, props };
}

export function userResetPasswordRequestFail(error) {
  return { type: actionTypes.USER_RESET_PASSWORD_REQUEST_FAIL, error };
}

export function userResetPasswordResponse(data) {
  return { type: actionTypes.USER_RESET_PASSWORD_RESPONSE, data };
}

export function onHideModal(props) {
  return { type: actionTypes.HIDE_MODAL, props };
}
