import * as actionTypes from '../actionTypes/profile';

export function userGetProfileRequest(payload, props) {
  return { type: actionTypes.USER_GETPROFILE_REQUEST, payload, props };
}

export function updateProfileRequest(payload, props) {
  return { type: actionTypes.USER_UPDATEPROFILE_REQUEST, payload, props };
}

export function deleteProfileRequest(payload, props) {
  return { type: actionTypes.USER_DELETEPROFILE_REQUEST, payload, props };
}

export function userGetProfileRequestFail(error) {
  return { type: actionTypes.USER_GETPROFILE_REQUEST_FAIL, error };
}

export function userGetProfileResponse(data) {
  return { type: actionTypes.USER_GETPROFILE_RESPONSE, data };
}

export function userUpdateProfileResponse(data) {
  return { type: actionTypes.USER_UPDATEPROFILE_RESPONSE, data };
}

export function deleteProfileResponse(data) {
  return { type: actionTypes.USER_DELETEPROFILE_RESPONSE, data };
}

export function onHideModal(props) {
  return { type: actionTypes.HIDE_MODAL, props };
}
