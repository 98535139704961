import * as types from '../../actions/actionTypes/auth';

const initialState = {
  isFetching: false,
  error: '',
  jwt: '',
  isModal: false,
  isAuthenticated: false,
  userid: '',
  message: '',
};

export default (prevState = initialState, action) => {
  switch (action.type) {
    case types.AUTH_REQUEST:
      return Object.assign({}, prevState, { isFetching: true, isModal: false });
    case types.AUTH_REQUEST_FAIL:
      return Object.assign({}, prevState, { isFetching: false, error: action.error, isModal: true });
    case types.AUTH_RESPONSE:
      return Object.assign({}, prevState, { isFetching: false, isAuthenticated: true, message: action.data.data !== undefined ? action.data.data.message : null, userid: action.data.data !== undefined ? action.data.data.user_id : null, jwt: action.data.jwt, isModal: false });
    case types.AUTH_SIGNOUT:
      return Object.assign({}, prevState, { isAuthenticated: false, isModal: false });
    case types.HIDE_MODAL:
      return Object.assign({}, prevState, { isModal: false });
    default:
      return prevState;
  }
};
