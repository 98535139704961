import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Routes, Switch } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import { StripeProvider } from 'react-stripe-elements';
import PublicRoute from '../../components/publicRoute';
import PrivateRoute from '../../components/privateRoute';
import Spinner from '../../components/spinner';
import PageNotFound from '../../components/pageNotFound';

const Signin = lazy(() => import('../login/signin/index'));
const Signup = lazy(() => import('../login/signup/index'));
const ErrorPage = lazy(() => import('../../components/error'));
const ForgotPassword = lazy(() => import('../login/forgotPassword/index'));
const ResetPassword = lazy(() => import('../login/resetPassword/index'));
const Profile = lazy(() => import('../profile/index'));
const Payment = lazy(() => import('../stripe/index'));
const Userlist = lazy(() => import('../admin/userlist'));
const UpdateLocation = lazy(() => import('../updatelocation/index'));
const LandingPage = lazy(() => import('../landingpage/index'));
const Terms = lazy(() => import('../newterms/termsconditions'));

const Router = () => (
  <HttpsRedirect>
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PK_KEY}>
      <Routes>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <PublicRoute exact path="/" component={LandingPage} loginHeader loginFooter />
            <PublicRoute path="/sign-in" component={Signin} />
            <PublicRoute path="/sign-up" component={Signup} />
            <PublicRoute path="/forgot-password" component={ForgotPassword} />
            <PublicRoute path="/reset-password" component={ResetPassword} />
            <PublicRoute path="/error" component={ErrorPage} />
            <PrivateRoute path="/profile" component={Profile} header footer />
            <PrivateRoute path="/payment" component={Payment} header footer />
            <PrivateRoute path="/userlist" component={Userlist} header footer />
            <PrivateRoute path="/updatelocation" component={UpdateLocation} header footer />
            <PublicRoute path="/terms" component={Terms} />
            <PublicRoute component={PageNotFound} />
          </Switch>
        </Suspense>
      </Routes>
    </StripeProvider>
  </HttpsRedirect>
);
export default Router;
