import React from 'react';
import { Link } from 'react-router-dom';
import Connect from './ConnectWithLifeCycle';

let isProfile = false;
let isPayment = false;
let isUserlist = false;
let isUpdateLocation = false;
let rolename = '';
let hidemenu = '';
let isAccountConfig=false;

const onClickProfile = () => {
  isProfile = true;
  isPayment = false;
  isUserlist = false;
  isUpdateLocation = false;
};

const onClickPayment = () => {
  isProfile = false;
  isPayment = true;
  isUserlist = false;
  isUpdateLocation = false;
};

const onClickUserlist = () => {
  isProfile = false;
  isPayment = false;
  isUserlist = true;
  isUpdateLocation = false;
};

const onClickUpdateLocation = () => {
  isProfile = false;
  isPayment = false;
  isUserlist = false;
  isUpdateLocation = true;
};

const Menu = () => (
  <Connect
    componentWillMount={(cmdProps: props) => {
      if (localStorage.getItem('roleid') !== '1') {
        document.body.classList.remove('adminusr');
        document.body.classList.add('nrmluser');
        rolename = 'nrmluseritem';
        hidemenu = '';
      } else {
        document.body.classList.remove('nrmluser');
        document.body.classList.add('adminusr');
        rolename = 'adminusritem';
        hidemenu = 'nrmluseritem';
      }

      if(localStorage.getItem("ispaymentdone")==="yes"){
        isAccountConfig = true;
      } else {
        isAccountConfig = false;
      }

      if (window.location.href.toLowerCase().indexOf('profile') !== -1) {
        isUserlist = false;
        isPayment = false;
        isProfile = true;
        isUpdateLocation = false;
      } else if (window.location.href.toLowerCase().indexOf('payment') !== -1) {
        isProfile = false;
        isUserlist = false;
        isPayment = true;
        isUpdateLocation = false;
      } else if (window.location.href.toLowerCase().indexOf('userlist') !== -1) {
        isProfile = false;
        isPayment = false;
        isUserlist = true;
        isUpdateLocation = false;
      } else if (window.location.href.toLowerCase().indexOf('location') !== -1) {
        isProfile = false;
        isPayment = false;
        isUserlist = false;
        isUpdateLocation = true;
      }
    }}
  >
    {() => (
      <div className="sidemenu">
        <nav className="navbar navbar-expand-md bg-dark navbar-dark">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav">
              <li role={rolename} className={isUserlist ? 'nav-item active' : 'nav-item'}>
                <Link to="userlist" onClick={onClickUserlist} className="nav-link">
                  <i className="fa fa-users margin-right15 iconwrap" />
                  <span className="revvsidemenuitem">Userlist</span>
                </Link>
              </li>
              <li className={isProfile ? 'nav-item active' : 'nav-item'}>
                <Link to="profile" onClick={onClickProfile} className="nav-link">
                  <i className="fa fa-user margin-right20 iconwrap" />
                  <span className="revvsidemenuitem">Profile</span>
                </Link>
              </li>
              <li role={hidemenu} className={isPayment ? 'nav-item active' : 'nav-item'}>
                <Link to="payment" onClick={onClickPayment} className="nav-link">
                  <i className="fa fa-money margin-right15 iconwrap" />
                  <span className="revvsidemenuitem">Payment</span>
                </Link>
              </li>
              <li role={hidemenu} className={isUpdateLocation ? 'nav-item active' : 'nav-item'}>                
				<Link to="updatelocation" onClick={onClickUpdateLocation} className="nav-link update-location">
                  <i className="fa fa-map-marker margin-update-location-right20 iconwrap" />
                  <span className="revvsidemenuitem">Update Locations</span>
                </Link>
              </li>
              <li role={hidemenu} className={isAccountConfig ? '' : 'hide'}>
                <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_ACCOUNTCONFIG_URL} className="nav-link">
                  <i className="fa fa-tasks margin-right15 iconwrap" />
                  <span className="revvsidemenuitem">Preferences</span>
                </a>
              </li>
              <li role={hidemenu}>
                <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_REVVSURVEY_URL} className="nav-link">
                  <i className="fa fa-laptop margin-right15 iconwrap" />
                  <span className="revvsidemenuitem">Revv Portal</span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    )}
  </Connect>
);
export default Menu;
