import * as actionTypes from '../../actions/actionTypes/signup';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  isFetching: false,
  error: '',
  isModal: false,
  captcha: '',
  message: '',
  userid: '',
};

export default (prevState = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_PROFILE_REQUEST:
      return Object.assign({}, prevState, { isFetching: true, isModal: false });
    case actionTypes.USER_PROFILE_REQUEST_FAIL:
      return Object.assign({}, prevState, { isFetching: false, error: action.error, isModal: true });
    case actionTypes.USER_PROFILE_RESPONSE:
      return Object.assign({}, prevState, { isFetching: false, userid: action.data !== undefined ? action.data.data.user_id : null, message: action.message, isModal: true });
    case actionTypes.HIDE_MODAL:
      return Object.assign({}, prevState, { isModal: false });
    default:
      return prevState;
  }
};
