import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import authReducers from './auth';
import userReducers from './users/signup';
import forgotPasswordReducer from './users/forgotPassword';
import resetPasswordReducer from './users/resetPassword';
import profileReducer from './profile/index';
import stripeReducer from './stripe/index';
import userlistReducer from './admin/userlist';
import updateLocationReducer from './updatelocation/index';

const reducer = combineReducers({
  user: userReducers,
  forgotpassword: forgotPasswordReducer,
  resetpassword: resetPasswordReducer,
  profile: profileReducer,
  stripe: stripeReducer,
  userlist: userlistReducer,
  updatelocation: updateLocationReducer,
  auth: authReducers,
  form: reduxFormReducer,
});
export default reducer;
