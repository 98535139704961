import * as actionTypes from '../../actions/actionTypes/stripe';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  isFetching: false,
  error: '',
  isModal: false,
  loadermessage: '',
  captcha: '',
  message: '',
  usersdata: null,
  transactiondata: null,
  modalShow: false,
};

export default (prevState = initialState, action) => {
  switch (action.type) {
    case actionTypes.STRIPE_TRANSACTION_REQUEST:
    case actionTypes.STRIPE_REFUND_REQUEST:
      return Object.assign({}, prevState, { isFetching: true, isModal: false, loadermessage: '' });
    case actionTypes.STRIPE_REQUEST:
      return Object.assign({}, prevState, { isFetching: true, isModal: false, loadermessage: 'test' });
    case actionTypes.STRIPE_REQUEST_FAIL:
      return Object.assign({}, prevState, { isFetching: false, error: action.error, isModal: true });
    case actionTypes.STRIPE_RESPONSE:
      if (action.data.error !== '') {
        if (document.querySelector('button[id="idsubmit"]') != null) {
          document.querySelector('button[id="idsubmit"]').disabled = false;
        }
      }
      return Object.assign({}, prevState, { isFetching: false, loadermessage: '', error: action.data.error, message: action.data, isModal: true });
    case actionTypes.STRIPE_TRANSACTION_RESPONSE:
      if (action.data.error !== '') {
        if (document.querySelector('button[id="idsubmit"]') != null) {
          document.querySelector('button[id="idsubmit"]').disabled = false;
        }
      }
      return Object.assign({}, prevState, { isFetching: false, error: action.data.error, transactiondata: action.data !== undefined ? action.data : [], isModal: false });
    case actionTypes.HIDE_MODAL:
      return Object.assign({}, prevState, { isModal: false });
    default:
      return prevState;
  }
};