import { takeEvery, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { apiCall } from '../../util';
import * as userActionTypes from '../../actions/actionTypes/forgotPassword';
import { userForgotPasswordResponse, userForgotPasswordRequestFail, onHideModal } from '../../actions/users/forgotPassword';

function* sendForgotPasswordRequest(userDetails) {
  const userCallback = { callbackurl: `${process.env.REACT_APP_SITE_URL}reset-password` };
  Object.assign(userDetails.payload, userCallback);
  const response = yield apiCall('forgotpassword', 'POST', userDetails);
  if (response.status !== 200) {
    yield put(userForgotPasswordResponse(''));
    yield put(userForgotPasswordRequestFail(response.data.error));
    yield (delay(3000));
    yield put(onHideModal());
  } else {
    yield put(userForgotPasswordRequestFail(''));
    yield put(userForgotPasswordResponse(response.data.message));
    yield (delay(3000));
    yield put(onHideModal());
  }
}

export function* takeForgotPasswordRequest(): Generator<*, *, *> {
  yield takeEvery(
    userActionTypes.USER_FORGOT_PASSWORD_REQUEST,
    sendForgotPasswordRequest,
  );
}
