import { takeEvery, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { apiCall } from '../../util';
import * as userActionTypes from '../../actions/actionTypes/profile';
import { getUserDetailsRequest, getUserDetailsResponse } from '../../actions/admin/userlist';
import { userGetProfileRequestFail, onHideModal, userGetProfileResponse, userUpdateProfileResponse, deleteProfileResponse } from '../../actions/profile/index';

function* sendGetProfileRequest(userDetails) {
  const response = yield apiCall('getuserprofiledata', 'POST', userDetails);
  if (response.status !== 200) {
    yield put(userGetProfileResponse(''));
    yield (delay(3000));
  } else {
    yield put(userGetProfileResponse(response.data.data));
    yield (delay(3000));
  }
}

function* updateProfileRequest(userDetails) {
  const response = yield apiCall('updateprofiledata', 'POST', userDetails);
  if (response.status !== 200) {
    yield put(userUpdateProfileResponse(''));
    if (response.error === undefined) {
      yield put(userGetProfileRequestFail(response.data.error));
    } else {
      yield put(userGetProfileRequestFail(response.error));
    }
    yield (delay(3000));
    yield put(onHideModal());
  } else {
    yield put(userUpdateProfileResponse(response.data.message));
    yield (delay(3000));
    yield put(onHideModal());
    if (window.location.href.toLowerCase().indexOf('userlist') !== -1) {
      const pageNo = [];
      pageNo.payload = [parseInt(localStorage.getItem('currentpageno'), 5)];
      const adminresponse = yield apiCall('getpaginateuserlist', 'POST', pageNo);
      if (response.status !== 200) {
        yield put(getUserDetailsRequest(''));
      } else {
        yield put(getUserDetailsResponse(adminresponse.data));
      }
    } else if (window.location.href.toLowerCase().indexOf('profile') !== -1) {
      yield put(userGetProfileResponse(response.data.data));
    }
  }
}

function* deleteProfileRequest(userDetails) {
  const response = yield apiCall('deleteprofile', 'POST', userDetails);
  if (response.status !== 200) {
    yield put(deleteProfileResponse(''));
    if (response.error === undefined) {
      yield put(userGetProfileRequestFail(response.data.error));
    } else {
      yield put(userGetProfileRequestFail(response.error));
    }
    yield (delay(3000));
    yield put(onHideModal());
  } else if (window.location.href.toLowerCase().indexOf('userlist') !== -1) {
    yield put(deleteProfileResponse(response.data.message));
    yield (delay(3000));
    yield put(onHideModal());
  } else {
    yield put(deleteProfileResponse(''));
  }
}

export function* takeProfileRequest(): Generator<*, *, *> {
  yield takeEvery(
    userActionTypes.USER_GETPROFILE_REQUEST,
    sendGetProfileRequest,
  );
}

export function* updProfileRequest(): Generator<*, *, *> {
  yield takeEvery(
    userActionTypes.USER_UPDATEPROFILE_REQUEST,
    updateProfileRequest,
  );
}

export function* delProfileRequest(): Generator<*, *, *> {
  yield takeEvery(
    userActionTypes.USER_DELETEPROFILE_REQUEST,
    deleteProfileRequest,
  );
}
